import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function updateReviewComment(id, { comment }) {
  try {
    await axios.put(`/reviews/${id}/comment`, { comment }, { headers: { Accept: 'application/json' } });
    return true;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateSurveyReviewComment(id, { comment }) {
  try {
    await axios.put(`/survey_reviews/${id}/update_comment`, { comment }, { headers: { Accept: 'application/json' } });
    return true;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateReviewStatus(id, { status }) {
  try {
    const response = await axios.put(`/reviews/${id}/update_review_status`, { status }, { headers: { Accept: 'application/json' } });
    return { status: response.status, success: response.data.success, error: response.data.error };
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function deleteGoogleReviewResponse({ reviewId, clinicId }) {
  try {
    let url = `/clinics/${clinicId}/google_reviews/${reviewId}`;
    const res = await axios.delete(url);
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function submitReviewResponse({ reviewResponse, reviewId, responseTemplateName }) {
  let url = `/reviews/${reviewId}/review_response`;
  try {
    const res = await axios.put(
      url,
      { review: { id: reviewId, response: reviewResponse, response_template_name: responseTemplateName || null } },
      { headers: { Accept: 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function submitSurveyReviewResponse({ reviewResponse, reviewId }) {
  let url = `/survey_reviews/${reviewId}/update_response`;
  try {
    const res = await axios.put(url, { survey_review: { id: reviewId, response: reviewResponse } }, { headers: { Accept: 'application/json' } });
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function submitGoogleReviewResponse({ reviewResponse, reviewId, clinicId }) {
  let url = `/clinics/${clinicId}/google_reviews/${reviewId}/reply`;
  try {
    const res = await axios.put(url, { google_review: { id: reviewId, message: reviewResponse } }, {});
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function submitSendReviewToConnector({ reviewId, clinicId, type }) {
  try {
    let url = '';
    let res = undefined;
    if (type === 'google') {
      url = `/clinics/${clinicId}/google_reviews/${reviewId}/send_to_connector`;
      res = await axios.put(url, { google_review: { id: reviewId } }, { headers: { Accept: 'application/json' } });
    } else {
      url = `/reviews/${reviewId}/send_to_connector`;
      res = await axios.put(url, { review: { id: reviewId } }, { headers: { Accept: 'application/json' } });
    }
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function createReviewResponseTemplate({ clinicId, reviewResponse, templateName }) {
  let url = `/clinics/${clinicId}/review_response_templates`;
  try {
    const res = await axios.post(url, {
      review_response_template: {
        content: reviewResponse,
        name: templateName,
      },
    });
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateReviewResponseTemplate({ reviewResponse, reviewResponseTemplateId, templateName }) {
  let url = `/review_response_templates/${reviewResponseTemplateId}`;
  try {
    const res = await axios.put(
      url,
      { review_response_template: { content: reviewResponse, name: templateName } },
      { headers: { Accept: 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateGoogleReviewResponseTemplate({ googleReviewResponse, googleReviewResponseTemplateId, googleReviewResponseTemplateName }) {
  let url = `/google_review_response_templates/${googleReviewResponseTemplateId}`;
  try {
    const res = await axios.put(url, { google_review_response_template: { content: googleReviewResponse, name: googleReviewResponseTemplateName } });
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function fetchSuggestionAi({ reviewId }) {
  let url = `/reviews/${reviewId}/fetch_suggestion_ai`;
  return axios.get(url, { headers: { Accept: 'application/json' }, timeout: 15000 });
}

export async function createGoogleReviewResponseTemplate({ clinicId, reviewResponse, templateName, ratingSentiment, templateOrder, mode }) {
  let url = `/clinics/${clinicId}/google_review_response_templates`;
  try {
    const res = await axios.post(url, {
      google_review_response_template: {
        content: reviewResponse,
        name: templateName,
        rating_sentiment: ratingSentiment,
        order: templateOrder,
        mode: mode,
      },
    });
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function deleteGoogleReviewResponseTemplate({ googleReviewResponseTemplateId }) {
  let url = `/google_review_response_templates/${googleReviewResponseTemplateId}`;
  try {
    const res = await axios.delete(url);
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function deleteReviewResponseTemplate({ reviewResponseTemplateId }) {
  let url = `/review_response_templates/${reviewResponseTemplateId}`;
  try {
    const res = await axios.delete(url);
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function fetchGoogleSuggestionAi({ googleReviewId, clinicId }) {
  let url = `/clinics/${clinicId}/google_reviews/${googleReviewId}/fetch_suggestion_ai`;
  return axios.get(url, { headers: { Accept: 'application/json' }, timeout: 15000 });
}

export async function fetchGoogleReviewResponseTemplateSuggestionAi({ clinicId, ratingSentiment }) {
  let url = `/clinics/${clinicId}/google_review_response_templates/fetch_suggestion_ai`;
  return axios.get(url, {
    headers: { Accept: 'application/json' },
    params: { rating_sentiment: ratingSentiment },
    timeout: 15000,
  });
}

export async function updateUserPreferenceToHideIaAlertMessage() {
  let url = `/users/update_user_preference_to_hide_ia_alert_message`;
  return axios.put(url, {}, { headers: { Accept: 'application/json' } });
}

export async function activateAutomaticGoogleReviewResponseFeature({ clinicId, sentiment }) {
  let url = `/automatic_response_settings/${clinicId}/define_automatic_response_to_google_reviews_feature_activation`;
  const key_to_activate = `activate_${sentiment}`;
  return axios.put(url, { [key_to_activate]: true }, { headers: { Accept: 'application/json' } });
}
export async function deactivateAutomaticGoogleReviewResponseFeature({ clinicId, sentiment }) {
  let url = `/automatic_response_settings/${clinicId}/define_automatic_response_to_google_reviews_feature_activation`;
  const key_to_activate = `activate_${sentiment}`;
  return axios.put(url, { [key_to_activate]: false }, { headers: { Accept: 'application/json' } });
}

export async function boostReview({ reviewId }) {
  let url = `/reviews/${reviewId}/boost`;
  return axios.post(url, { headers: { Accept: 'application/json' } });
}
